<template>
    <div class="faq">
        <div class="container">
            <div class="col-12 col-xl-10 offset-xl-1">
                <div class="row">
                    <div class="col-12 mt-5 text-center titulo">
                        <h1>FAQ</h1>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-6 offset-xl-3 mt-5">
                <div class="row">
                    <div class="col-12">
                        <div
                            class="accordion accordion-flush"
                            id="accordionFlushExample"
                        >
                            <div v-for="(item, index) in faq" :key="index" class="accordion-item">
                                <h2
                                    class="accordion-header"
                                    :id="'flush-heading' + index"
                                >
                                    <button
                                        class="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        :data-bs-target="'#flush-collapse' + index"
                                        aria-expanded="false"
                                        :aria-controls="'flush-collapse' + index"
                                    >
                                        {{item.pergunta}}
                                    </button>
                                </h2>
                                <div
                                    :id="'flush-collapse' + index"
                                    class="accordion-collapse collapse"
                                    :aria-labelledby="'flush-heading' + index"
                                    data-bs-parent="#accordionFlushExample"
                                >
                                    <div class="accordion-body">
                                        <span v-html="item.resposta"></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Faq",

    data(){
        return{
            faq: [
                {
                    pergunta: 'Como realizo um pedido?',
                    resposta: 'Vá até a página de <a href="/produtos" target="_blank">Produtos</a> e adicione - os  no carrinho. O sistema faz o cálculo dos produtos e frete se existir.'
                },
                {
                    pergunta: 'Quais as formas de pagamento?',
                    resposta: 'No momento, é o PIX através do QRCODE gerado pelo site ou pela chave caso tenha algum problema.'
                },
                {
                    pergunta: 'Realizei um pedido, como consulto?',
                    resposta: 'Todo pedido realizado pelo sistema, gera um código no formato <i>BT-NNNNNN-AA-AAAAA-NN</i>. É através desse código que poderá consultar a logística do mesmo na página de <a href="/meus-pedidos" target="_blank">Meus Pedidos</a>. Lembrando que as informações básicas são informadas para o e-mail do usuário.'
                },
                {
                    pergunta: 'Tive algum problema com meu pedido, o que devo fazer?',
                    resposta: 'Você deve entrar em contato conosco relatando qual o tipo de problema, para que possamos solucioná-lo da melhor forma possível.'
                },
                {
                    pergunta: 'Quais regiões são atendidas?',
                    resposta: 'Isso varia de acordo com a região. No momento entregamos somente para o estado de SP, limitado a 100 Km ou de acordo com a disponibilidade do nosso sistema.'
                },
                {
                    pergunta: 'Os produtos possuem alguma restrição de consumo?',
                    resposta: 'Sim. Os produtos contém leite, creme de leite, leite condensado, amendoim ou chocolate em sua composição. Portanto, pessoas que tem intolerância a lactose ou alergia não podem consumi-los.'
                },
                {
                    pergunta: 'Vocês criam produtos específicos para algum tipo de restrição de consumo?',
                    resposta: 'Sim. Temos produtos para quem não pode consumir açúcar. Entre em contato para mais informações.'
                },
                {
                    pergunta: 'Qual o prazo de entrega?',
                    resposta: 'O prazo de entrega é de 3 a 5 dias, dependendo do produto e quantidade pedida. Vale ressaltar que a entrega do tipo delivery é feita no mesmo dia em que o produto for finalizado.',
                },
                {
                    pergunta: 'Qual o prazo de validade dos produtos?',
                    resposta: '<ul><li>Truffas - 10 dias</li><li>Pães de mel - 5 dias</li><li>Barrinhas de oleaginosas - 5 dias</li><li>Mousses (com ou sem ganache) - 5 dias</li><li>Arroz doce - 3 dias</li><li>Torta de limão - 3 dias</li><li>Brigadeiros - 2 dias</li></ul>'
                }
            ]
        }
    }
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
.faq {
    @include background;

    .titulo {
        @include titulo;
    }

    .accordion-item {
        margin-bottom: 13px;
        border: 0;

        .accordion-button {
            background-color: $marrom;
            color: $branco;
            font-family: $roboto;
            font-weight: 500;
            font-style: normal;
            font-size: 17px;
            line-height: 17px;
            &::after {
                content: "\279C";
                  background-image: none;
                transform: rotate(90deg);
            }
        }
        .accordion-button:not(.collapsed)::after {
            background-image: none;
            transform: rotate(-90deg);
        }
    }

    p {
        color: $marrom;
        font-family: $roboto;
        font-weight: 500;
        font-style: normal;
        font-size: 17px;
        line-height: 24px;
    }
}
@media (max-width: 767.98px) {
    .faq {
        padding: 50px 0 80px;
    }
}
</style>